import Vue from "vue";
import Vuex from "vuex";
import persistedState from "vuex-persistedstate"; //首先引入
Vue.use(Vuex);

export default new Vuex.Store({
  //所有的数据都放在state中
  state: {
    token: "",
    usersname: "",
    userroles: "",
    rolesname: "",
    usergroupid: "",
    companyId: ""
  },

  //操作数据，唯一的通道是mutations
  mutations: {
    setTokenValue(state, value) {
      state.token = value;
    },
    setUserNameValue(state, value) {
      state.usersname = value;
    },
    setUserrolesValue(state, value) {
      state.userroles = value;
    },
    setUsergroupidValue(state, value) {
      state.usergroupid = value;
    },
    setCompanyIdValue(state, value) {
      state.companyId = value;
    },
    setRolesNameValue(state, value) {
      state.rolesname = value;
    }
  },

  //actions,可以来做异步操作，然后提交给mutations，而后再对state(数据)进行操作
  actions: {},
  plugins: [persistedState()] //加上这个就可以了
});
