import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

//路由懒加载模式
const routes = [
  {
    path: "/",
    redirect: "/login"
  },
  {
    path: "/login",
    component: () => import(/*webpackChunkName:"Login"*/ "./Login.vue")
  },
  {
    path: "/home",
    component: () => import(/*webpackChunkName:"Home"*/ "./Home.vue"),
    redirect: "/welcome",
    children: [
      {
        path: "/welcome",
        component: () =>
          import(/*webpackChunkName:"Welcome"*/ "./components/Welcome.vue")
      },
      {
        path: "/users",
        component: () =>
          import(/*webpackChunkName:"Users"*/ "./components/user/Users.vue")
      },
      {
        path: "/usersgroup",
        component: () =>
          import(
            /*webpackChunkName:"UsersGroup"*/ "./components/user/UsersGroup.vue"
          )
      },
      {
        path: "/salecompany",
        component: () =>
          import(
            /*webpackChunkName:"SaleCompany"*/ "./components/sales/SaleCompany.vue"
          )
      },
      {
        path: "/salecontact",
        component: () =>
          import(
            /*webpackChunkName:"SaleContact"*/ "./components/sales/SaleContact.vue"
          ) //SaleContact
      },
      {
        path: "/saleactivity",
        component: () =>
          import(
            /*webpackChunkName:"SaleActivity"*/ "./components/sales/SaleActivity.vue"
          ) //SaleActivity
      },
      {
        path: "/technologyactivity",
        component: () =>
          import(
            /*webpackChunkName:"TechnologyActivity"*/ "./components/technology/TechnologyActivity.vue"
          ) //TechnologyActivity
      },
      {
        path: "/serviceactivity",
        component: () =>
          import(
            /*webpackChunkName:"ServiceActivity"*/ "./components/service/ServiceActivity.vue"
          )
      },
      {
        path: "/marketcompany",
        component: () =>
          import(
            /*webpackChunkName:"MarketCompany"*/ "./components/market/MarketCompany.vue"
          )
      },
      {
        path: "/marketcontact",
        component: () =>
          import(
            /*webpackChunkName:"MarketContact"*/ "./components/market/MarketContact.vue"
          )
      },
      {
        path: "/marketdemand",
        component: () =>
          import(
            /*webpackChunkName:"MarketDemand"*/ "./components/market/MarketDemand.vue"
          )
      },
      {
        path: "/marketactivity",
        component: () =>
          import(
            /*webpackChunkName:"MarketActivity"*/ "./components/market/MarketActivity.vue"
          )
      },
      {
        path: "/redevelopmentactivity",
        component: () =>
          import(
            /*webpackChunkName:"RedevelopmentActivity"*/ "./components/redevelopment/RedevelopmentActivity.vue"
          )
      },
      {
        path: "/printerlocket",
        component: () =>
          import(
            /*webpackChunkName:"PrinterLocket"*/ "./components/labellock/PrinterLocket.vue"
          )
      },
      {
        path: "/workHour",
        component: () =>
          import(
            /*webpackChunkName:"WorkHour"*/ "./components/report/workHour.vue"
          )
      },
      {
        path: "/qrkuanpackfile",
        component: () =>
          import(
            /*webpackChunkName:"qrkuanpackfile"*/ "./components/qrkuanpack/qrkuanpackfile.vue"
          )
      },
      {
        path: "/qrkuanpackvideo",
        component: () =>
          import(
            /*webpackChunkName:"qrkuanpackvideo"*/ "./components/qrkuanpack/qrkuanpackvideo.vue"
          )
      },
      {
        path: "/qrkuanpackdownloadfile",
        component: () =>
          import(
            /*webpackChunkName:"qrkuanpackdownloadfile"*/ "./components/qrkuanpack/qrkuanpackdownloadfile.vue"
          )
      }
    ]
  }
];
const router = new VueRouter({
  routes
});

//判断token值
router.beforeEach((to, from, next) => {
  if (to.path === "/login") return next();
  const tokenStr = window.sessionStorage.getItem("token");
  if (!tokenStr) return next("/login");
  next();
});
export default router;
