import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store"; //导入store
//import "./plugins/element.js";
//导入全局样式表
import "./assets/css/global.css";
//导入字体图标
import "./assets/fonts/iconfont.css";

import config from "../public/config.json"; // 引用

import axios from "axios";
//导入Nprogress包
import Nprogress from "nprogress";
import "nprogress/nprogress.css";
const configData = config;
axios.defaults.baseURL = configData.baseUrl;
//console.log('baseUrl:' + configData.baseUrl);

//axios.defaults.baseURL = "http://124.71.172.179:3001/api/private/v1/";
//设置http请求时带上token值
axios.interceptors.request.use(config => {
  Nprogress.start();
  config.headers.Authorization = window.sessionStorage.getItem("token");
  return config;
});

axios.interceptors.response.use(config => {
  Nprogress.done();
  return config;
});

Vue.prototype.$http = axios;
Vue.config.productionTip = false;
/* eslint-disable no-new */

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
